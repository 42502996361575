import { LabelHTMLAttributes } from "react"

export default function InputLabel({
  value,
  className = "",
  children,
  ...props
}: LabelHTMLAttributes<HTMLLabelElement> & { value?: string }) {
  return (
    <label
      {...props}
      className={
        `block text-sm font-medium leading-5 text-gray-700 ` + className
      }
    >
      {value ? value : children}
    </label>
  )
}
